//Classic
import Dashboard from "../views/Dashboard";
import Ended from "../views/Dashboard";
import RestaurantManagement from "../views/RestaurantManagement";
import Card from "../views/Dashboard/InvitationCard";
// import GuestView from "../views/GuestManagement";
import SuperAdminAnalytics from "../views/SuperAdminAnalytics";
import SuperAdminDashboard from "../views/SuperAdminDashboard";
import AdminManagement from "../views/AdminManagement";
import MenuManagement from "../views/MenuManagement";
import PackageManagement from "../views/PackageManagement";
import RestaurantAdminSetting from "../views/RestaurantAdminSetting";
import RestaurantAnalytics from "../views/RestaurantAnalytics";
import RestaurantCRM from "../views/RestaurantCRM";
import PublicView from "../views/PublicRestaurantManagement";
import PublicRestaurantDetails from "../views/PublicRestaurantManagement/Restaurants";
import FavPublicRestaurantManagement from "../views/PublicRestaurantManagement/RestaurantCards/FavRestaurantCards"
import CategoryRestaurantCards from "../views/PublicRestaurantManagement/RestaurantCards/CategoryRestaurantCards";
import ClientProfile from "../views/ClientProfile";
//Auth
import Login from "../views/Authentication/LogIn/LoginClassic";
import AdminLogin from "../views/Authentication/LogIn/LoginClassic/AdminLogin";
import AdminSignup from "../views/Authentication/SignUp/SignupClassic/AdminSignUp";
import CustomerSignup from "../views/Authentication/SignUp/SignupClassic/CustomerSignUp";
import ForgotPassword from "../views/Authentication/ForgotPassword";
import ResetPassword from "../views/Authentication/ForgotPassword/ResetPassword";
import Error404 from "../views/Authentication/Error404/Error404";
import Error503 from "../views/Authentication/Error503/Error503";

export const routes = [
  { path: "/dashboard", exact: true, component: Dashboard },
  { path: "/ended", exact: true, component: Ended },
  { path: "/restaurant", exact: true, component: RestaurantManagement },
  { path: "/admin", exact: true, component: AdminManagement },
  { path: "/konfemAdmin", exact: true, component: SuperAdminAnalytics },
  { path: "/superadmindashboard", exact: true, component: SuperAdminDashboard },
  // { path: "guest", exact: true, component: GuestView },
  { path: "/package", exact: true, component: PackageManagement },
  { path: "/menu", exact: true, component: MenuManagement },
  { path: "/settings", exact: true, component: RestaurantAdminSetting },
  { path: "/analytics", exact: true, component: RestaurantAnalytics },
  { path: "/crm", exact: true, component: RestaurantCRM },
  { path: "/profile", exact: true, component: ClientProfile },
  { path: "/konfemAdmin/clientAnalytics", exact: true, component: SuperAdminAnalytics },
  { path: "/konfemAdmin/restaurantAnalytics", exact: true, component: SuperAdminAnalytics },
  { path: "/konfemAdmin/eventAnalytics", exact: true, component: SuperAdminAnalytics },
  //Error
  { path: "/error-404", exact: true, component: Error404 },
  { path: "/favourites", exact: true, component: FavPublicRestaurantManagement },
];

export const publicRoutes = [
  {
    path: "/",
    exact: true,
    component: PublicView,
  },
  {
    path: "restaurants/:id",
    exact: true,
    component: PublicRestaurantDetails,
  },
  {
    path: "restaurantsCategory/:category",
    exact: true,
    component: CategoryRestaurantCards,
  },
];

export const authRoutes = [
  { path: "/", exact: true, component: Login },
  // { path: "/:restaurantId", exact: true, component: Login },
  { path: "/partner", exact: true, component: AdminLogin },
  { path: "/admin-signup", exact: true, component: AdminSignup },
  { path: "/customer-signup", exact: true, component: CustomerSignup },
  { path: "/forgot-password", exact: true, component: ForgotPassword },
  {
    path: "/reset-password/:resetToken",
    exact: true,
    component: ResetPassword,
  },
  { path: "/error-503", exact: true, component: Error503 },
  { path: "/rsvp/:id", exact: true, component: Card },
];
