import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';

import defaultRecommendedImg from '../../../assets/developImg/defaultRecommendedImg.png';


const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const RecommendedRestaurantCards = ({ restaurantsInfo, clientInfo }) => {
  const history = useHistory();
  const [recommended, setRecommended] = useState([]);

  useEffect(() => {
    if (restaurantsInfo.length === 0) return;

    // Filter recommended restaurants by isBoarded, get their logos from 1st element of photoFiles
    const recommendedRestaurants = restaurantsInfo
    //   .filter(restaurant => restaurant.isBoarded)
      .slice(30, 35)
      .map(restaurant => ({
        id: restaurant._id,
        name: restaurant.restaurantName,
        logo: restaurant.restaurantLogo
      }));

    setRecommended(recommendedRestaurants);
  }, [restaurantsInfo]);

  const handleClickCounter = async (id) => {
    // Update clicks for each restaurant
    const timestamp = new Date();
    const username = clientInfo?.clientName || "Unknown";
    const userId = clientInfo?._id || "Unknown";
    const data = { timestamp, username, userId };

    await fetch(`${backendUri}/api/v0/public/restaurants/updateClicks/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    history.push(`/restaurants/${id}`);
  };

  return (
    <div>
      {Array.isArray(recommended) && recommended.length > 0 ? (
        <>
          <h6 className="mt-2 fw-bold">Must-Try Spots You'll Love!</h6>
          <div style={{ width: '100%', height: '100px', overflow: 'auto', marginTop: '10px' }}>
            <div className="photo-grid" style={{ display: 'flex', overflowX: 'auto', gap: '10px' }}>
              {recommended.map((restaurant, index) => (
                <div 
                  key={index} 
                  style={{ flexShrink: '0', position: 'relative', width: '100px', height: '100px', borderRadius: '10px', overflow: 'hidden' }}
                  onClick={() => handleClickCounter(restaurant.id)}
                >
                  <img
                    className="img-fluid rounded-5"
                    src={restaurant.logo || defaultRecommendedImg}
                    alt={`Restaurant logo ${index + 1}`}
                    style={{ width: '100%', height: '100%', objectFit: 'cover', filter: 'blur(1px)' }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'white',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      textAlign: 'center',
                      padding: '10px',
                      boxSizing: 'border-box',
                      fontSize: '12px',
                    }}
                  >
                    {restaurant.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default RecommendedRestaurantCards;
