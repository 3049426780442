import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import useClientServices from "../../../services/clientService";
import useRestaurantServices from "../../../services/restaurantService";
import useEventServices from "../../../services/eventService";
import ClientAnalytics from "./ClientAnalytics";
import ClientList from "./ClientList";


const ClientPage = () => {
  const { clientsData } = useClientServices();
  const [validUserList, setValidUserList] = useState([]);
  const [adminCreatedUsers, setAdminCreatedUsers] = useState([]);

  const { restaurantsData } = useRestaurantServices();
  const { eventsData } = useEventServices();
  const [eventsList, setEventsList] = useState([]);

  useEffect(() => {
    if (clientsData) {
      const validUsers = clientsData.clients.filter(client => client.clientEmail);
      // Sort restaurantsData by onBoardedStartDate
      const sortedValidUsersData = [...validUsers].sort((a, b) => {
        return new Date(a.signUpDate) - new Date(b.signUpDate);
      });
      setValidUserList(sortedValidUsersData);

      const adminUsers = clientsData.clients.filter(client => !client.clientEmail);
      setAdminCreatedUsers(adminUsers);
    }
  }, [clientsData]);

  useEffect(() => {
    if (restaurantsData && clientsData) {
      const validUsers = clientsData.clients.filter(client => client.clientEmail);
  
      const updatedValidUserList = validUsers.map(user => {
        const favRestaurantsData = user.favRestaurants
          .map(favRestaurantId =>
            restaurantsData.find(restaurant => restaurant._id === favRestaurantId)
          )
          .filter(Boolean); // Filter out any undefined entries
  
        return { ...user, favRestaurantsData };
      });
  
      setValidUserList(updatedValidUserList);
    }
  }, [restaurantsData, clientsData]);

  useEffect(() => {
    if (restaurantsData && eventsData) {
      const updatedEventsList = eventsData.map(event => {
        const restaurantName = restaurantsData.find(restaurant => restaurant._id === event.restaurantId)?.restaurantName;
        return { ...event, restaurantName };
      });

      setEventsList(updatedEventsList);
    }
  }, [restaurantsData, eventsData]);

  return (
    <Box className="font-monospace" p={3}>
      <ClientAnalytics validUserList={validUserList} adminCreatedUsers={adminCreatedUsers}/>
      <ClientList validUserList={validUserList} eventsList={eventsList}/>
    </Box>
  );
};

export default ClientPage;
